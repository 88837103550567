h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    font-weight: 400;
}

.title {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: bold;
    margin-block-start: 2rem;
}

    .title.gray {
        color: #707070;
    }

    .title > svg {
        color: initial;
        font-size: inherit;
        margin-inline-start: 1rem;
    }

a {
    color: lightskyblue;
    font-family: sans-serif
}
    a:hover {
        color: lightskyblue;
    }

.custom-container-width {
}


@media (min-width: 576px) {
    .custom-container-width {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .custom-container-width {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .custom-container-width {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .custom-container-width {
        max-width: 1140px;
    }
}

@media (min-width: 1440px) {
    .custom-container-width {
        max-width: 80%;
    }
}

.custom-footer {
    margin-top: 1rem;
    padding-block: 1rem;
}

@media (min-width: 576px) {
    .custom-footer {
        margin-top: 1rem;
        padding-block: 1rem;
    }
}

@media (min-width: 992px) {
    .custom-footer {
        margin-top: 3rem;
        padding-block: 1rem;
    }
}

.btn-confirm{
    background-color: cornflowerblue;
}

.btn-cancel {
    background-color: red;
}

.btn-tab {
    background-color: white;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    flex: 0 0 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        flex: 0 0 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        flex: 0 0 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        flex: 0 0 20%;
        float: left;
    }
}